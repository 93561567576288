/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import 'react-responsive-carousel/lib/styles/carousel.css';
import './src/assets/styles/base.scss';


